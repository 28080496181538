import { Injectable } from '@angular/core';
import { ApiEndPoints } from 'projects/admin/src/app/core/constants/api-endpoints.constants';
import { ApiService } from 'projects/admin/src/app/core/services';
import { RoleService } from 'projects/admin/src/app/core/services/role.service';
import DISPLAY_MESSAGES from 'projects/admin/src/app/shared/constants/message';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';
import { throwError, BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
@Injectable()
export class SpinWheelService {
  constructor(
    private httpService: ApiService,
    private roleService: RoleService,
    private toast: ToastService
  ) {}

  // Dummy Data Added for Spin Wheel -> Active/Winners Tab
  public spinWheelUsersDetails = new BehaviorSubject<any>({
    spinWheelHistoryDetails: [
      {
        SWID: '1',
        Q_Business: 'aabusiness',
        Week_of: '3',
        Q_Pon_ID: '1we2',
        Quantity: '3',
        Amount: '20',
        Spoke: '3',
        Redeemed: 'yes',
      },
    ],
    spinWheelActiveDetails: [
      {
        SWID: '1',
        Q_Business: 'aabusiness',
        Week_of: '3',
        Q_Pon_ID: '1we2',
        Quantity: '3',
        Amount: '20',
        Spoke: '3',
        Redeemed: 'yes',
        Approved: true,
      },
    ],
    spinWheelWinnersDetails: [
      {
        SWID: '1',
        Q_member: 'aabusiness',
        Week_of: '3',
        Q_Pon_ID: '1we2',
        Discount: '3',
        Amount: '20',
        Spoke: '3',
        Redeemed: 'yes',
        QID: '1esw3',
      },
    ],
  });

  /**
   * @description Get Create Spin Wheel Detail On User Type
   */
  getCreateSpinWheelUserDetails() {
    return this.httpService
      .get(`${ApiEndPoints.admin}${ApiEndPoints.createSpinWheelType}`)
      .pipe(
        catchError((error) => this.erroHandler(error)),
        map((data) => {
          return data.result;
        })
      );
  }

  /**
   * @description Save the Spoke Details for Spin wheel
   * @param payload is request body
   */
  submitSpinWheelDetails(payload: any) {
    return this.httpService
      .post(`${ApiEndPoints.admin}${ApiEndPoints.submitSpinWheel}`, payload)
      .pipe(
        catchError((error) => throwError(error)),
        map((data) => {
          return data;
        })
      );
  }

  /**
   * @description  Get Spin Wheel User Details -> Active/Winners Tab Method
   * @param type details type like History , active or Pending
   */
  getSpinWheelUsersDetails(type: any): Observable<any> {
    let data;
    // return this.httpService.get(`${ApiEndPoints.admin}/spiwheeldetails?userId=12&userType=Admin&list=history`).pipe(
    //   map( (response : Response) => { return response })
    // )
    switch (type) {
      case 'History':
        data = this.spinWheelUsersDetails.asObservable();
        break;
      case 'Active':
        data = this.spinWheelUsersDetails.asObservable();
        break;
      case 'Winners':
        data = this.spinWheelUsersDetails.asObservable();
        break;
    }
    return data;
  }

  /**
   * @description Get Q_Clubs_Businesses List method
   * @param Q-club-id
   */
  getQClubBusinesses(id: number, params?: any): Observable<any> {
    const qponFilter = params.monthFor ? `/${params.monthFor}` : '';
    return this.httpService
      .get(
        `${ApiEndPoints.qClubDetails}${id}${qponFilter}/businesses?has_qpon=true`
      )
      .pipe(
        catchError((error) => throwError(error)),
        map((response: Response) => {
          return response;
        })
      );
  }

  /**
   * @description Get LoggedIn User Type
   */
  getUserRole() {
    return this.roleService.getUserRole();
  }

  /**
   * @description Added to handle pagination
   */
  getPaginationDetails() {
    return {
      activePage: 1,
      records: 10,
      disableNext: false,
    };
  }

  /**
   * @description construct a Date field for UI
   * @param month new Date.getMonth()
   * @param year new Date.getFullYear()
   */
  getMinDate(monthsToAdd=0) {
    const date =
      new Date().getFullYear().toString() +
      '-' +
      (new Date().getMonth() + 1  +monthsToAdd  < 10 ? '0' : '') +
      (new Date().getMonth() + 1+monthsToAdd).toString().slice(-2);
    return date;
  }
  /**
   * @description created to Handle The Errors of NFC Module
   */
  erroHandler(error) {
    this.toast.show(error.error.message, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
    return throwError(error);
  }

  /**
   * @description Populate Success Msg for this Module
   * @param msg to show Success msg
   */
  showSuccessMessage() {
    this.toast.showSuccess(DISPLAY_MESSAGES.spinWheelCreated);
  }

  /**
   * @description get spin wheel for the month
   */
  getSpinWheelForMonth = (params: any) => {
    return this.httpService
      .get(
        `${ApiEndPoints.admin}${ApiEndPoints.submitSpinWheel}/${
          params.monthFor
        }${params.compact ? `?compact=${params.compact}` : ''}`
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  };

  /**
   * @description http request to fetch all Qdreams records
   * @param payload api payload
   */
  fetchAllQDreams(payload: any): Observable<any> {
    const filters = `status=${payload.status}&compact=${payload.compact}`;
    const apiPath = `admins/q-dream?${filters}`;
    return this.httpService.get(apiPath).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * @description http request to fetch all Spinwheer under QClub
   * @param payload api payload
   */
  getQClubSpinwheels(id: number, query: any, payload: any): Observable<any> {
    // const filters = `status=${payload.status}&compact=${payload.compact}`;
    let apiPath = `${ApiEndPoints.admin}${ApiEndPoints.qclubs}${id}${ApiEndPoints.spinWheelsListing}`;
    apiPath += `${query.searchTerm ? `?searchTerm=${query.searchTerm}` : ''}`;

    return this.httpService.post(apiPath, payload).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * @description http request to fetch spin wheel by id
   */
  getSpinWheelById(id: number): Observable<any> {
    // const filters = `status=${payload.status}&compact=${payload.compact}`;
    const apiPath = `${ApiEndPoints.admin}${ApiEndPoints.spinWheel}/${id}`;

    return this.httpService.get(apiPath).pipe(
      map((data) => {
        return data;
      })
    );
  }
  /**
   * @description format a Date field for UI
   * @param month new Date.getMonth()
   * @param year new Date.getFullYear()
   */
  formatToMinDate(dateToFormat) {
    const date =
      new Date(dateToFormat).getFullYear().toString() +
      '-' +
      (new Date(dateToFormat).getMonth() + 1 < 10 ? '0' : '') +
      (new Date(dateToFormat).getMonth() + 1).toString().slice(-2);
    return date;
  }

  /**
   * @description Update the Spoke Details for Spin wheel
   * @param payload is request body
   */
  updateSpinWheelDetails(payload: any, id: number) {
    return this.httpService
      .patch(`${ApiEndPoints.admin}${ApiEndPoints.spinWheel}/${id}`, payload)
      .pipe(
        catchError((error) => throwError(error)),
        map((data) => {
          return data;
        })
      );
  }
  /**
   * @description Delete the Spoke Details for Spin wheel
   */
  deleteSpinWheelDetails(id: number) {
    return this.httpService
      .delete(`${ApiEndPoints.admin}${ApiEndPoints.spinWheel}/${id}`)
      .pipe(
        catchError((error) => throwError(error)),
        map((data) => {
          return data;
        })
      );
  }
}
