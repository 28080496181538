import { Injectable } from '@angular/core';
import { ApiService } from 'projects/admin/src/app/core/services/api.service';
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class QingoService {
  constructor(private apiService: ApiService) {}

  /**
   * @description construct a Date field for UI
   * @param month new Date.getMonth()
   * @param year new Date.getFullYear()
   */
  getMinDate(monthToAdd =0) {
    const date =
      new Date().getFullYear().toString() +
      '-' +
      (new Date().getMonth() + 1 +monthToAdd < 10 ? '0' : '') +
      (new Date().getMonth() + 1 + monthToAdd).toString().slice(-2);
    return date;
  }

  /**
   * @description create and edit qingo
   * @param editId qingo id
   * @param payload api payload
   */
  assignQingo(payload, editId?): Observable<any> {
    const path = editId ? `admins/qingo/${editId}` : `admins/qingo`;
    const methodObsrvable = editId
      ? this.apiService.patch(path, payload)
      : this.apiService.post(path, payload);
    return methodObsrvable.pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * @description Get Qingo details by id
   * @param id qingo id
   */
  fetchQingoById(id): Observable<any> {
    return this.apiService.get(`/admin/qingo/${id}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * @description fetch Quingo Details
   * @param params param filters
   */

  fetchQingoDetails(params): Observable<any> {
    const { id, monthFor } = params;
    return this.apiService
      .get(
        `admins/qingo${id ? `?id=${id}` : ''}${
          monthFor && !id ? `?monthFor=${monthFor}` : ''
        }`
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * @description return a config of tabs
   * @param flag flag
   */
  getConfig(qingoPatterns, flag?) {
    const config: any = {};
    Object.keys(qingoPatterns).forEach((item, i) => {
      config[qingoPatterns[item]] = {
        type: item,
        page: 1,
        active: i === 0 ? true : false,
      };
    });

    if (flag) {
      config['REDEEM 3 QPONS'] = {
        type: '3QPONS',
        page: 1,
        active: false,
      };
    }

    return config;
  }

  /**
   * @description method to fetch quingo winners
   * @param params parmas filters
   */
  getQingoWinners(params: any): Observable<any> {
    const path = `admins/qingo/winners`;
    return this.apiService.get(path, params).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * @description get created quingo list
   * @param params param filters
   */
  getQingoList(params: any): Observable<any> {
    const path = `admins/qingo/list`;
    return this.apiService.get(path, params).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * delete a draw only by superadmin
   * @param id draw id
   */
  getEntarntsForDraw(drawId, payload): Observable<any> {
    return this.apiService.get(`admins/qingo/entrants/${drawId}`, payload);
  }
}
