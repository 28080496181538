import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, AbstractControlDirective, ValidatorFn } from '@angular/forms';
import PATTREN from 'projects/admin/src/app/shared/constants/pattern.constants';
export class CustomValidators {
  /**
   * @description validate the actual email address
   * @param control form control
   */
  static validEmailAddress(control: AbstractControl) {
    if (control.value) {
      const isValidEmail = PATTREN.emailPattern.test(control.value);
      if (!isValidEmail) {
        const message = {
          validEmail: {
            message: 'Please enter a valid email address',
          },
        };
        return message;
      }
    } else {
      return null;
    }
  }

  /**
   * @description validate the name
   * @param control form control
   */
  static onlyChar(control: AbstractControl) {
    if (control.value) {
      const isValidChar = PATTREN.namePattern.test(control.value);
      if (!isValidChar) {
        const message = {
          uniqueName: {
            message: 'Please enter the string only',
          },
        };
        return message;
      }
    } else {
      return null;
    }
  }

  /**
   * @description validate the number only
   * @param control form control
   */
  static onlyNumber(control: AbstractControl) {
    if (control.value) {
      const isValidNumber = PATTREN.numbers.test(control.value);
      if (!isValidNumber) {
        const message = {
          uniqueName: {
            message: 'Please enter the Number only',
          },
        };
        return message;
      }
    } else {
      return null;
    }
  }

  static minValueZero(control: AbstractControl)  {
    if (control.value) {
      const isValidNumber = control.value>0
      if (!isValidNumber) {
        const message = {
          uniqueName: {
            message: 'Please enter  Number greater than 0',
          },
        };
        return message;
      }
    } else {
      return null;
    }
  }
}
